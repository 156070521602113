@import "src/theme/colors.module.scss";

.container {
  width: 100%;
  padding: 13px 0px;
  overflow-y: auto;
  overflow-x: hidden;

  &__lable_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    &__label {
      color: $gray-700;
    }
  }

  &__filter_container {
    transition: height ease-out 0.5s;

    &.active {
      height: fit-content;
      transition: height ease-out 0.5s;
    }

    overflow: auto;

    &__item {
      margin-bottom: 8px;
    }
  }
}
