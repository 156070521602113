@import 'src/theme/colors.module.scss';

.input_field_group {
    display: flex;
    flex-direction: column;
    gap: 6px;
    input[type='number'] {
        /* Firefox */
        -moz-appearance: textfield;
    }

    /* WebKit browsers (Chrome, Safari) */
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Edge */
    input[type='number']::-ms-inner-spin-button,
    input[type='number']::-ms-outer-spin-button {
        appearance: none;
    }
}

.input {
    border: solid 1px #d0d5dd;
    text-indent: 12px;
    border-radius: 8px;
    outline: #66e2b0;
    height: 44px;
    font-size: 16px;
    font-family: inherit;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: color 9999s ease-out, background-color 9999s ease-out;
        transition-delay: 9999s;
        -webkit-transition: color 9999s ease-out,
            background-color 9999s ease-out;
        -webkit-transition-delay: 9999s;
    }

    &[error] {
        outline: #fda29b;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee4e2;
        border-color: #fda29b;
    }
}
.get_otp_button {
    position: absolute;
    border: none;
    outline: none;
    background-color: transparent;
    color: #d0d5dd;
    cursor: not-allowed;
    display: flex;
    gap: 12px;
    bottom: 0%;
    height: 100%;
    border-inline-start: solid 1px #d0d5dd;
    padding: 8px;
    align-items: center;

    &[btn-variant='active'] {
        color: #344054;
        cursor: pointer;
    }

    &[dir='rtl'] {
        left: 3%;
    }

    &[dir='ltr'] {
        right: 3%;
    }
}
.select_day_wrapper {
    display: flex;
    justify-content: space-between;
}

.select_day_button {
    background-color: #eaecf0;
    width: 49%;
    height: 64px;
    border-radius: 8px;

    cursor: pointer;

    &:hover {
        color: #009b5d;
        background-color: #ccf5e5;
        border: 1px solid #009b5d;
    }

    &[active] {
        color: #018651;
        background-color: #ccf5e5;
        border: 1px solid #009b5d;
    }
}
.select_day_button_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.select_time_button {
    background-color: #eaecf0;
    width: 32%;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    border: none;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:not(.disabled):hover {
        color: #009b5d;
        background-color: #ccf5e5;
        border: 1px solid #009b5d;
        font-weight: 600;
    }

    &[active] {
        color: #018651;
        background-color: #ccf5e5;
        border: 1px solid #009b5d;
    }
}
.select_time_button_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mobile_input_rtl {
    text-align: right;
    padding-right: 12px;
}

.additionalDataContainer {
    display: flex;
    align-items: center;
}

.checkboxInput {
    cursor: pointer;
    margin-inline-end: 8px;
}

.newItemWrapper {
    background-color: $primary-50;
    border-radius: 8px 0px 8px 0px;
    padding: 1px 6px;
    color: $primary-600;
    display: inline;
}
.placeholderInput {
    border: 1px solid $gray-300;
    border-radius: 8px;
    box-shadow: 0px 1px 2px $gray-900-opac;
    padding-inline: 14px;
    padding-block: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
}
.newContactInfoContainer {
    padding-inline-start: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.checkboxSubtitle {
    color: $gray-500;
    // margin-bottom: 16px;
}
.errorMessage {
    color: $error;
    text-align: start;
}
