@import 'src/theme/colors.module.scss';

.banners_container_mobile {
    position: relative;
    overflow: hidden;
    background: white;
    width: 100%;

    border-radius: 12px;
    padding: 0px 16px 0px 16px;
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 8px;
    }
}

.banner_header {
    font-size: 3rem;
    font-weight: bold;
    color: white;
    z-index: 2;
    line-height: 1;
    opacity: 1;
}
.page_container {
    padding: 0px 16px 16px 16px;
    box-shadow: 0px 24px 16px -10px rgba(16, 24, 40, 0.22);
    -webkit-box-shadow: -2px 24px 16px -10px rgba(16, 24, 40, 0.22);
    -moz-box-shadow: -2px 24px 16px -10px rgba(16, 24, 40, 0.22);
}
.inspection_location_question {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        transform: rotateZ(20deg);
        margin-top: 15px;
        vertical-align: inherit;
        display: inline;
    }
    .reverse_down_arrow {
        transform: rotateY(180deg);
    }
}

.is_sticky_question {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding: 16px;
}
.sticky_step {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px 0px 0px;
    button {
        color: #00673e;
    }
}
.hidden {
    display: none;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.value_proposition_buttons {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    margin-top: -12px;
    // margin: 4px;
    padding: 4px 4px;
    border-radius: 8px;
    background-color: #f2f4f7;
    button {
        display: flex;
        height: 44px;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 700;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        color: #002246;
        background-color: #f2f4f7;
        border-radius: 8px;
        span {
            margin: 0px 6px;
            display: flex;
            align-items: center;
        }
    }
    .active {
        background: var(--secondary-600, #002246);
        color: white;
    }
}

.value_propositions {
    padding: 4px 0px;
    div {
        margin-top: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 450;
        color: #4b5565;
        img {
            margin: 0px 5px;
        }
    }
    .see_more {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 500;
        button {
            font-size: 14px;
            font-weight: 600;
            color: #00673e;
        }
        .reverse_down_arrow {
            transform: rotateY(180deg);
        }
    }
    .see_more_hidden {
        height: 20px;
        * {
            display: none;
        }
    }
}

.sepration_line {
    margin: 8px 0px;
    border-top: 1px solid var(--gray-200, #eaecf0);
}

.loaction_hint {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #697586;
    height: 70px;
    span {
        margin-inline-start: 3px;
        margin-top: -2.5px;
    }
    img {
        color: #697586;
    }
}
.location_info_container {
    height: 70px;
}
.hub_pin_container {
    padding-bottom: 3px;
    p:first-of-type {
        display: inline-block;
        margin: 0px 3px;
    }
    .directions {
        margin-inline-start: 20px;
        display: flex;
        justify-content: space-between;
    }
}

.next_step {
    margin-top: 8px;
    margin-bottom: 5px;
}
.next_button {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        margin: 0px 4px 0px 4px;
    }
}
.button {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    img {
        margin: 0px 6px;
    }
}

.sections_cont {
    h2 {
        text-align: center;
        margin: 20px 0px;
        line-height: 32px;
        font-size: 24px;
        color: $gray-800;
    }
    padding: 0px 15px 15px 15px;
}
.inspection_vid_cont {
    width: 100%;
    margin-top: 32px;
    border-radius: 8px;
    overflow: hidden;
    background-color: black;
    height: 300px;
    position: relative;
}

.steps_to_cell {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-bottom: 48px;
}

.sell_step {
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        &:first-of-type {
            color: $gray-800;
        }
        &:nth-of-type(2) {
            color: $gray-600;
        }
    }
}

.why_sell {
    background-color: #ccd5df;
    padding-bottom: 32px;
    h2 {
        text-align: center;
        padding: 32px;
    }
}

.why_sell_card {
    margin: 0px 16px 16px 16px;
    padding: 20px;
    border-radius: 10px !important;
    p {
        &:first-of-type {
            color: $gray-800;
        }
        &:nth-of-type(2) {
            color: $gray-600;
        }
    }
}
.why_sell_grid {
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    margin: 15px;
    margin-bottom: 32px;
    gap: 5%;
    div {
        p {
            &:first-of-type {
                color: $gray-800;
            }
            &:nth-of-type(2) {
                color: $gray-600;
            }
        }
    }
}

.button_small {
    width: 50%;
    height: 45px;
    border-radius: 6px;
    margin: 0px auto;
    font-weight: 700;
    display: flex;
    align-items: center;
    direction: rtl;
    justify-content: center;
}

.counter_section {
    margin-top: 50px;
    padding: 15px;
    display: grid;
    grid-template-columns: 50% 50%;
}
.counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $gray-800;
    .count_img {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background-color: #ccf5e5;
    }
    .count_num {
        color: $primary;
        font-size: 35px;
        font-weight: bold;
        margin: 17px 0px;
    }
}
