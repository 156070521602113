@media only screen and (min-width: 600px) {
    .testimonialItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 36px 28px;
        gap: 16px;
        background: #f2f4f7;
        border-radius: 16px;
        // max-width: 550px;
        margin-right: 8px;
        height: 288px;
        overflow-y: hidden;
        @media only screen and (max-width: 600px) {
            padding: 40px 16px;
            // width: 70vw;
        }
    }
    .reviewContainer {
        color: black;
        position: relative;
        overflow-y: scroll;
        min-height: 120px;
        overflow: hidden;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        border-radius: 8px;
        -webkit-transition: 0.15s ease-in 0s;
        -moz-transition: 0.15s ease-in 0s;
        -o-transition: 0.15s ease-in 0s;
        transition: 0.15s ease-in 0s;
    }

    .review {
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 1000;
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        z-index: 50;
        height: max-content;
    }
    .reviewContainer::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        overflow-y: hidden;
    }

    .reviewContainer::-webkit-scrollbar-thumb {
        border-radius: 4px;
    }
    .reviewContainer::-webkit-scrollbar-track {
        -webkit-appearance: none;
        background-color: #00673e00;
    }
}
@media only screen and (max-width: 600px) {
    .testimonialItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        background: #f2f4f7;
        border-radius: 16px;
        margin-right: 9px;
        overflow-y: hidden;
        padding: 40px 16px;
        // width: 70vw;
    }
    .reviewContainer {
        color: #ffffff;
        position: relative;
        overflow-y: scroll;
        min-height: 120px;
        border-radius: 8px;
        -webkit-transition: 0.15s ease-in 0s;
        -moz-transition: 0.15s ease-in 0s;
        -o-transition: 0.15s ease-in 0s;
        transition: 0.15s ease-in 0s;
    }

    .review {
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 1000;
        overflow-y: scroll;
        color: black;
        z-index: 50;
        height: max-content;
        p {
            color: black !important;
        }
    }
    .reviewContainer::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        overflow-y: hidden;
    }

    .reviewContainer::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: gray;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    .reviewContainer::-webkit-scrollbar-track {
        -webkit-appearance: none;
        background-color: #00673e00;
    }
}

.attributionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    gap: 16px;
    margin-top: auto;
}
.nameAndDate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    color: black;
}
.name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
////////////////////////////////////////////
// @media only screen and (max-width: 344px) {
//     .testimonialItem {
//         max-width: 60vw;
//     }
// }

// @media only screen and (max-width: 360px) and (min-width: 345px) {
//     .testimonialItem {
//         max-width: 60vw;
//     }
// }
// @media only screen and (max-width: 425px) and (min-width: 361px) {
//     .testimonialItem {
//         display: flex;
//         flex-direction: column;
//         max-width: 60vw;
//     }
// }
// @media only screen and (max-width: 600px) and (min-width: 426px) {
//     .testimonialItem {
//         max-width: 60vw;
//     }
// }
