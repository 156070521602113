@import 'src/theme/dimensions';
@import 'src/theme/colors.module.scss';

.container {
    // width: 67vw;
    width: 100%;

    margin-inline-start: 3%;
    @media screen and #{$mobileView} {
        margin-inline: 0px;
        padding-bottom: 0%;
        flex: 1;
        justify-content: space-between;
    }
}

.stepperContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media screen and #{$mobileView} {
        padding-inline: 16px;
    }
}
.activeStepContainer {
    margin-top: 20px;
    @media screen and #{$mobileView} {
        margin-top: 24px;
        padding-inline: 16px;
        height: 100vh;
    }
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 112px;

    h6 {
        font-size: 16px;
        line-height: 24px;
        margin-top: 5px;
    }

    .prevBtn {
        width: 92px;
        height: 48px;
        border: 1px solid $gray-300;
        background: $white;
        box-shadow: 0px 1px 2px $gray-900-opac;
        border-radius: 8px;
        cursor: pointer;

        &:disabled {
            border-color: $gray-200;

            h6 {
                color: $gray-300;
            }
        }

        h6 {
            color: $gray-700;
        }
    }

    .nextBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 137px;
        padding-inline: 10px;
        height: 48px;
        background: $success-600;
        border: 1px solid $gray-300;
        box-shadow: 0px 1px 2px $gray-900-opac;
        border-radius: 8px;

        h6 {
            color: $white;
            margin-top: 5px;
        }

        .icon {
            width: 5px;
            height: 10px;
            margin-inline-start: 15px;

            &.iconReversed {
                transform: rotateY(180deg);
            }
        }

        &:disabled {
            background-color: $gray-300;
            color: $white;
        }
    }

    @media screen and #{$mobileView} {
        display: none;
    }
}
.primaryColor {
    color: $primary-600;
}
.mobileCheckoutBtn {
    display: none;
    z-index: 10;
    background-color: $white;
    @media screen and #{$mobileView} {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-inline: 16px;
        border-top: 1px solid $gray-200;
        padding-block-start: 12px;
        .bookingDetails {
            display: flex;
            justify-content: space-between;
            padding-block-end: 10px;

            h6 {
                color: $secondary-500;

                &:first-of-type {
                    font-weight: 400;
                    font-size: 16px;
                }

                &:last-of-type {
                    font-weight: 500;
                    font-size: 18px;
                }
            }
        }
        .btn {
            background-color: $primary-600;
            min-height: 48px;
            padding: 10px 18px;
            border: none;
            border-radius: 8px;
            color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 12px;
            justify-content: center;

            &:disabled {
                background-color: $gray-300;
                color: $white;
            }

            h6 {
                &.title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0px;
                }

                &.subtitle {
                    font-weight: 500;
                    font-size: 12px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.reservationFees {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: $secondary-500;

    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        &.firstRow {
            margin-top: 10px;
        }
    }

    .arrow {
        width: 30px;
        height: 15px;
        transition: 0.4s ease-in-out;

        &.open {
            transform: rotateX(180deg);
        }
    }
}

.hidden{
    display: none;
}