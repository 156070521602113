@import "src/theme/colors.module.scss";
.button {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border-left: 4px solid #4e5ba6;
  background: #f8f9fc;
  width: 100%;
  cursor: pointer;
}
.image_container {
  width: 48px;
  height: 48px;
  border-radius: 44.177px;
  background-color: $gray-blue-600;
  border: solid 3px $gray-blue-300;
  display: flex;
  align-items: center;
  justify-content: center;
  // animation: pulse 1s infinite;
  position: relative;
}
.img {
  animation: pulse 1s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-10px);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}
.spinner {
  position: absolute;
  width: 48px;
  height: 48px;
  border: 4px solid transparent;
  border-top-color: $gray-blue-400;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
