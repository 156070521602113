@import 'src/theme/dimensions';
@import 'src/theme/colors.module.scss';

.container {
    position: relative;
}

.PageLayout {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 112px 32px 112px;
    position: relative;

    @media #{$mobileView} {
        gap: 24px;
        padding: 32px 15px;
    }
}
.divider {
    display: none;
    @media #{$mobileView} {
        display: block;
        border-bottom: 1px solid $gray-100;
    }
}

.testamonialContainer {
    margin: 32px 112px 32px 112px;
    @media #{$mobileView} {
        margin: 32px 16px;
    }
}
