.otp_input {
  width: 51px !important;
  height: 64px;
  margin-right: 5px;
  border: solid 1px;
  border-color: #d0d5dd;
  border-radius: 8px;
  font-size: 18px;
  outline: none;
  color: black;
  text-align: center;
  font-family: inherit;

  &:focus {
    box-shadow: 0px 1px 2px #66e2b0;
    border-color: #66e2b0;
  }
}
.otp_input::-webkit-outer-spin-button,
.otp_input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.otp_input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
