@import "src/theme/colors.module.scss";

.flex_column_container {
  display: flex;

  &[datatype="space_between"] {
    justify-content: space-between;
  }

  &[datatype="center"] {
    justify-content: center;
  }
}

.card_container {
  width: 100%;
  border: 1px solid $gray-200;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.financier_img {
  object-fit: cover;
  max-height: 29px;
  max-width: 75px;
}
