@import 'src/theme/colors.module.scss';

.input_group_container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    position: relative;
}

.label {
    margin: 0px;
    font-size: 14px;
    color: rgba(52, 64, 84, 1);
    line-height: 20px;
}

.input_contianer {
    position: relative;
}
.input {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    // text-indent: 12px;
    border: 1px solid rgba(208, 213, 221, 1);
    border-radius: 8px;
    height: 44px;
    width: 100%;
    padding-inline-start: 38px;
    padding-inline-end: 32px;
}
.input:focus {
    outline: none !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #e6fbf2;
    border-color: #66e2b0;
}
.dropdown_container {
    box-shadow: rgb(171, 169, 171) 1px 1px 5px 0px;
    z-index: 101;
    position: absolute;
    background-color: white;
    width: 100%;
    text-align: start;
    min-height: 0;
    overflow-y: auto;
    top: 46px;
    max-height: 180px;
    &.open {
        border: solid 0.5px $gray-200;
        box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px 0px;
        border-radius: 4px;
        // max-height: 243px;
        border-top: none;
    }
}

.button {
    cursor: pointer;
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 0.5px $gray-200;
    width: 100%;
    padding-inline-start: 12px;
    text-align: start;
}
.button:hover {
    background-color: $gray-50;
}
.search_icon {
    position: absolute;
    top: 10px;
    margin-inline-start: 13px;
}
.clear_icon {
    position: absolute;
    top: 15px;
    cursor: pointer;
}
