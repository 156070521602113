@import 'src/theme/colors.module.scss';
@import 'src/theme/dimensions';

.imageWrapper {
    padding: 37px 21px;
}

.image {
    filter: grayscale(100%);
    width: 270px;
    @media screen and #{$desktopView} {
        width: 300px;
    }
}

.rootContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    text-align: center;
}

.innerTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
