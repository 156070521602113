@import "@/theme/colors.module.scss";
.container {
  @media screen and (max-width: 860px) {
    min-height: 520px;
    height: 100vh;
  }
}
.header {
  margin-block-end: 16px;

  .header_subtitle {
    color: #667085;
  }
}

.inputs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.first_row {
  display: flex;
  gap: 8px;
}

.input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .input_label {
    color: #667085;
    top: 10px;

    font-size: 16px;
    line-height: 24px;
    position: absolute;
    inset-inline-start: 14px;
    width: 10px;
  }

  .input_error {
    color: #f04438;
  }

  .input {
    height: 44px;
    padding: 10px 46px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: black;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    &::placeholder {
      color: #667085;
    }

    &:focus {
      outline-color: #d0d5dd;
      outline-width: 1px;
    }
  }
}
