.form_cont {
    display: flex;
    flex-direction: column;
    width: 94%;
    position: absolute;
    top: 50%;
    right: 3%;
}

.inputs_cont {
    background-color: white;
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 12px;
    z-index: 2;
}

.location_button_cont {
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 12px 24px 12px;
    border-radius: 0px 0px 12px 12px;
}

.contactInfo_dialog {
    display: flex;
    align-items: center;
    gap: 32px;
}

.contactInfo_inputs {
    width: 490px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px 16px 16px;
}

.contactInfo_img {
    background: #f9fafb;
    margin: 12px;
    border-radius: 8px;
    padding: 0px 4px;
}

.form_dialog {
    position: absolute;
    top: 30px;
}
