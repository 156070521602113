@import 'src/theme/colors.module.scss';

.container {
    width: 400px;
    // height: 364px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .textContainer {
            display: flex;
            flex-direction: column;

            .title {
                color: #101828;
            }

            .closeIcon {
                cursor: pointer;
            }
        }
    }

    .subtitle {
        color: #667085;
        margin-top: 8px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 850px) {
        width: unset;
        padding-inline: 16px;
        .header {
            align-items: baseline;
        }

        .subtitle {
            width: 75%;
        }
    }
}
.formContainer {
    display: flex;
    flex-direction: column;

    input[type='number'] {
        /* Firefox */
        -moz-appearance: textfield;
    }

    /* WebKit browsers (Chrome, Safari) */
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Edge */
    input[type='number']::-ms-inner-spin-button,
    input[type='number']::-ms-outer-spin-button {
        appearance: none;
    }

    .label {
        color: #344054;
        margin-bottom: 6px;
    }

    .input {
        margin-bottom: 20px;
        height: 44px;
        padding: 10px 14px;
        border: 1px solid #d0d5dd;
        outline: none;
        border-radius: 8px;
        touch-action: none;

        &::placeholder {
            color: #667085;
        }
    }
}

.btn {
    height: 44px;
    background-color: #009b5d;
    width: 100%;
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;

    &:disabled {
        background: #d0d5dd;
    }
}
.otpContainer {
    margin-bottom: 16px;

    .otpInput {
        display: flex;
        font-size: 28px;
        line-height: 60px;
        width: 44px !important;
        height: 64px;
        margin-bottom: 6px;
        box-shadow: 0px 1px 2px $gray-900-opac;
        border: 1px solid $gray-300;
        border-radius: 8px;
        text-align: center;
        font-family: inherit;
        outline: none;
        color: $black;

        &:focus {
            box-shadow: 0px 1px 2px $primary-300;
            border-color: $primary-300;
        }
    }
}
.otpInputsContainer {
    display: flex;
    justify-content: space-between;
}
.otpDisclaimer {
    padding: 0 6px;
    color: $gray-500;
}
.otpModalContentResendContainer {
    padding: 0 6px;
    margin-bottom: 16px;
    color: $gray-500;
}
.otpModalContentResend {
    display: inline;
    margin: 0 5px;
    cursor: pointer;
    border-bottom: 1px solid;
    color: $gray-500;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
