.options_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 2;

    @media screen and (min-width: 850px) {
        display: none;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
        height: 80%;
    }

    &.open {
        display: grid;
    }
}

.search_group {
    position: relative;
}
.search_input {
    width: 100%;
    border: solid 1px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #e6fbf2;
    border-color: #66e2b0;
    border-radius: 8px;
    outline: #66e2b0;

    position: relative;
    height: 44px;
    padding: 12px;
    text-indent: 24px;
}

.content_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    @media screen and (max-width: 850px) {
        gap: 8px;
    }
}
