@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.container {
  height: 44px;
  padding: 10px 14px;
  border: 1px solid $gray-300;
  box-shadow: 0px 1px 2px $gray-900-opac;
  border-radius: 8px;
  color: $gray-900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.placeholder {
  color: $gray-500;
}
// .input {
//   border: none;
//   outline: none;
//   color: $gray-900;
//   width: 80%;
//   ::placeholder {
//     color: $gray-500;
//   }
// }
.selected_item {
  color: $gray-900;
}

.arrow {
  width: 30px;
  height: 15px;
  transition: 0.4s ease-in-out;

  &.open {
    transform: rotateX(180deg);
  }
}

.menu {
  transition: 0.4s ease-in-out;
  max-height: 200px;
  min-height: 20px;
  background-color: white;
  position: absolute;
  width: 100%;
  margin-top: 8px;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px $gray-900-14,
  0px 4px 6px -2px $gray-900-08;
  border-radius: 8px;
  overflow: scroll;
}

.option {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  height: 48px;
  cursor: pointer;

  &.selected {
    background-color: $primary-50;
  }

  .text {
    color: $gray-700;
  }
}
