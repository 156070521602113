.container {
    position: relative;
    display: block;
    overflow-x: auto;
}
.innerBody {
    height: 500px;
    overflow-x: auto;
    width: 100%;
    overflow-y: scroll;
    position: relative;
}
@media only screen and (max-width: 850px) {
    .container {
        min-height: -webkit-fill-available;
        min-height: -moz-available;
        min-height: stretch;
        min-height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-self: end;
        overflow-x: auto;
    }
    .innerBody {
        height: calc(calc(var(--vh, 1vh) * 95) - 200px);
        justify-self: flex-end;
        overflow-x: auto;
        width: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        position: relative;
    }
}
