.container {
  width: 400px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .closeIcon {
    cursor: pointer;
    align-self: flex-end;
  }

  .bill {
    align-self: center;
    margin-bottom: 37px;
  }

  @media screen and (max-width: 850px) {
    width: unset;
    padding-inline: 16px;
  }
}

.title {
  color: #002d5d;
  text-align: center;
}
.subtitle {
  color: #667085;
  margin-top: 4px;
  text-align: center;
  width: 80%;
  align-self: center;
}

.btn {
  height: 44px;
  background-color: #009b5d;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: white;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconReversed {
  transform: rotateY(180deg);
}
