@import 'src/theme/colors.module.scss';

.headerContainer {
    padding: 0 5% 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px solid $gray-200;
}

.tick {
  position: absolute;
}

.pageTitle {
    color: $secondary;
    font-weight: 600;
}

.headerArrowContainerRTL {
    right: 1%;
    left: auto;
    top: 16px;
}

.headerArrowContainerLTR {
    left: 1%;
    right: auto;
}

.hidden {
    visibility: hidden;
}
