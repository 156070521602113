@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.badgeContainer {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

// Sylndr Certified
.SylndrCertifiedSectionCard {
  padding: 24px;
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #eaecf0;

  grid-auto-columns: 50% 50%;
  grid-template-areas:
        "badge paragraph"
        "header paragraph";

  @media #{$mobileView} {
    display: grid;
    grid-template-areas:
            "badge header"
            "badge paragraph";
    border: 0;
    gap: 8px;
    grid-auto-columns: auto;
    padding: 0;
  }
}

.SylndrCertifiedSectionCardBadgeSection {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  width: 50%;

  @media #{$mobileView} {
    flex-direction: row;
    gap: 12px;
    width: 100%;
  }
}

.SylndrCertifiedSectionCardBadgeSectionText {
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-area: header;

  @media #{$mobileView} {
    text-align: start;
    grid-area: header;
  }
}

.SylndrCertifiedSectionCardParagraphSection {
  grid-area: paragraph;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  text-align: start;
  line-height: 30px;

  @media #{$mobileView} {
    font-size: 12px;
    grid-area: paragraph;
    line-height: 18px;
  }
}
.sylndrCertifiedBadge {
  width: 100px;
  height: 72px;
  @media #{$desktopView} {
    width: 160px;
    height: 160px;
  }
}

.sylndrCertifiedHeader {
  font-size: 24px;
  color: $primary;
  @media #{$desktopView} {
    font-size: 48px;
  }
}

.sylndrCertifiedSectionCardBadgeImg {
  width: 160px;
  justify-self: center;

  @media #{$mobileView} {
    width: 60px;
    grid-area: badge;
  }
}

.badgeHeader {
  font-size: 30px;
  line-height: 38px;
  @media #{$mobileView} {
    font-size: 16px;
    line-height: 24px;
  }
}

.badgeSubHeader {
  font-size: 20px;
  line-height: 30px;
  @media #{$mobileView} {
    font-size: 14px;
    line-height: 20px;
  }
}
.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
