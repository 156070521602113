.work > div > div > div {
  justify-content: center;
}
.work {
  margin-bottom: 45px;
  padding: 40px 0;
  height: max-content;

  .title {
    text-align: center;
    letter-spacing: -0.04em;
    display: block;
    /* Secondary / Default */
    font-size: 2.25rem;
    color: #1a273b;
    margin-bottom: 20px;
  }
}

.workItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start !important;
  width: 25%;
  height: max-content;
  padding: 0px 12px;

  img {
    margin-bottom: 20px;
    height: 150px;
  }

  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */

    text-align: center;
    letter-spacing: -0.01em;

    /* Secondary / Default */

    color: #1a273b;
    margin-bottom: 10px;
  }
}

.workItemDesc {
  color: #0c0c0c;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 200%;
  margin-bottom: 0;
  opacity: 0.7;
  text-align: center;
}

.HowItWorksContainer {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  gap: 2rem;
  justify-content: center;
  margin: 0px auto;

  height: max-content;
}

.desktopContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileContainer {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .HowItWorksContainer {
    max-width: 90%;
  }
}

@media only screen and (max-width: 850px) {
  .HowItWorksContainer {
    max-width: 95%;
  }
}

@media only screen and (max-width: 769px) {
  .HowItWorksContainer {
    margin: 0px;
    max-width: 100%;
    width: 100%;
  }

  .mobileContainer {
    display: block;
    width: 100%;
  }

  .desktopContainer {
    display: none;
  }

  .workItem {
    display: flex !important;
    height: max-content;
  }

  .workItemDesc {
    font-size: 15px;
  }
}
