.input_group_container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.label {
  margin: 0px;
  font-size: 14px;
  color: rgba(52, 64, 84, 1);
  line-height: 20px;
}

.input_contianer {
  position: relative;
}
.input {
  color: rgba(102, 112, 133, 1);
  font-size: 16px;
  text-indent: 38px;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  height: 44px;
  width: 100%;
}
