@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.title {
  font-size: 42px;
  @media #{$mobileView} {
    font-size: 24px;
  }
}
.header {
  font-size: 30px;
  line-height: 38px;
  color: $primary;
  text-align: start;
  @media #{$mobileView} {
    font-size: 18px;
    line-height: 24px;
  }
}

.paragraph {
  font-size: 20px;
  line-height: 30px;
  color: $secondary;
  @media #{$mobileView} {
    font-size: 12px;
    line-height: 18px;
  }
}

.badgeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media #{$mobileView} {
    margin-bottom: 12px;
  }
}

.imageItem {
  height: 380px;
  width: 100%;
  @media #{$mobileView} {
    height: 209px;
  }
}
.mobileImage {
  @media #{$desktopView} {
    display: none;
  }
}
.desktopImage {
  @media #{$mobileView} {
    display: none;
  }
}

.HowItWorksSectionItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media #{$mobileView} {
    flex-direction: column;
    grid-area: content;
    padding: 0px;
    gap: 16px;
  }
}
.HowItWorksSectionItemReverse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media #{$mobileView} {
    flex-direction: column;
    grid-area: content;
    padding: 0px;
    gap: 16px;
  }
  flex-direction: row-reverse;
}

.HowItWorksSectionItemImg {
  width: 50%;

  @media #{$mobileView} {
    width: 100%;
  }
}

.HowItWorksContentSection {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 50%;

  @media #{$mobileView} {
    width: 100%;
  }
}

.HowItWorksContentSectionText {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.HowItWorksContentSectionNumberContainer {
  width: 32px;
  height: 32px;
  background-color: #e6fbf2;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 4px solid;
  border-color: #f3fffa;
  border-radius: 50px;
  @media #{$mobileView} {
    display: none;
  }
}

.HowItWorksContainerInner {
  position: relative;
  height: 100%;
}

.HowItWorksSectionContainer {
  @media #{$mobileView} {
    display: flex;
    gap: 16px;
    grid-template-areas: "dot content";
    // height: $how-it-works-container-height;
  }
}

.HowItWorksContentSectionDotContainer {
  min-width: 40px;
  height: 40px;
  background-color: #e6fbf2;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 4px solid;
  border-color: #f3fffa;
  border-radius: 50px;
  grid-area: dot;
  position: relative;
  @media #{$desktopView} {
    display: none;
  }
}
.HowItWorksContentSectionDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #009b5d;
}

.HowItWorksContentSectionLine {
  width: 2px;
  background-color: #009b5d;
  grid-area: line;
  position: absolute;
  top: 0;
  transform: translateY(20px);
  z-index: 2;
}

.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
