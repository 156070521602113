@import "src/theme/colors.module.scss";
.fileInputContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-300;
  border-radius: 8px;
  padding: 16px;

  .title {
    color: $secondary-500;
  }

  .subtitle {
    color: $gray-500;
    margin-top: 8px;
    margin-bottom: 4px;
  }
}

.fileInput {
  border: 1px solid $gray-300;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: copy;

  .icon {
    margin-bottom: 12px;
  }

  .title {
    display: flex;
    color: $primary-700;
    gap: 4px;
    margin-bottom: 4px;
    white-space: pre;

    .titleSpan {
      color: $gray-500;
    }
  }

  .disclaimer {
    color: $gray-500;
  }
}

.filesContainer {
  width: 100%;

  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 12px;
}

.fileContainer {
  width: 100%;
  border: 1px solid $gray-300;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex: 1;
}
.thumbnail {
  width: 101px;
  height: 64px;
  border-radius: 6px;
}
.details {
  flex: 1;
  overflow: hidden;

  .detailsFirstRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .trashIcon {
      cursor: pointer;
    }

    .trashIconNotAllowed {
      cursor: not-allowed;
      opacity: 0.4;
    }

    .nameAndSize {
      max-width: 40%;

      .fileName {
        color: $gray-700;
      }

      .fileSize {
        color: $gray-500;
      }
    }
  }

  .detailsSecoundRow {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
