.steps_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 0px;
    transition: all ease-in-out 0.1s;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 0;
    left: 0;
    background-color: white;
    z-index: 10;
    border-radius: 8px;

    &[steps-state='open'] {
        height: 390px;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
            0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        padding: 16px;
    }
}

.stepper_step_container {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    position: relative;
}
.step_active {
    background-color: #f3fffa;
    border-color: #99ebcb;
    .stepper_step_item_container:nth-of-type(2) {
        color: #009b5d;
    }
}

.stepper_step_item_container {
    display: flex;
    gap: 12px;
    color: #00673e;
}

.stepper_connector {
    position: absolute;
    top: 103%;
    height: 9px;
    width: 1px;
    background-color: #d0d5dd;
}
.stepper_connector_active {
    background-color: #66e2b0;
}
