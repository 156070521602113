.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 480px;
  @media screen and (max-width: 1100px) {
    padding: 0px;
    width: 100%;
    gap: 40px;
  }
}
.content_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.header_container {
  display: flex;
  justify-content: space-between;
}
.map_container {
  height: 180px;
  @media screen and (max-width: 1100px) {
    height: 220px;
  }
}
.direction_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
