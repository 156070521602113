@import "src/theme/dimensions.scss";
.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.containerInner {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 4px;
}
.containerInnerText {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  gap: 4px;
}

.viewBenefits {
  text-decoration: underline !important;
}
.popUpContainer {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-self: end;
  overflow-x: auto;
  @media screen and #{$mobileView} {
    width: 100%;
  }
}
.popUpContainerList {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  @media screen and #{$mobileView} {
    width: 100%;
    padding-top: 50px;
    height: calc(calc(var(--vh, 1vh) * 95));
    padding-bottom: 98px;
  }
}
.popUpContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 30;
  width: 100%;
  background-color: white;
  @media screen and #{$mobileView} {
    height: 30px;
    position: absolute;
    top: 0px;
  }
}

.mobileBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 16px;
  height: 98px;
  background-color: white;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  right: 0px;
}
.mobileContainer {
  overflow: hidden;
  height: calc(calc(var(--vh, 1vh) * 95));
}
.viewMoreContainer {
  display: flex;
  align-items: center;
}
