@import "src/theme/colors.module.scss";

.container {
  width: 100%;
  position: absolute;

  &__input_group {
    margin-bottom: 8px;

    &__label {
      color: $gray-700;
    }

    &__select_group {
      // position: relative;

      &__input {
        border: 1px solid $gray-300;
        border-radius: 8px;
        padding: 10px 14px 10px 14px;
        width: 100%;
        height: 44px;
        color: $gray-900;
        font-size: 1rem;
        font-weight: 400;

        &:focus {
          outline-color: $green-300;
        }

        &__dropdown {
          width: 100%;
          position: absolute;
          background-color: white;
          box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
          0px 4px 6px -2px rgba(16, 24, 40, 0.03);
          border: 1px solid #f2f4f7;
          z-index: 123;
          height: 200px;
          overflow: scroll;
          -webkit-overflow-scrolling: touch;

          &__item {
            width: 100%;
            padding: 14px 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &:hover {
              background-color: $gray-50;
            }

            &__text {
              color: $gray-900;
            }

            &__tick {
              width: 13px;
              height: 13px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .container {
    position: relative;

    &__input_group {
      position: absolute;
      z-index: 2;

      &__select_group__input__dropdown {
        position: unset;
      }
    }

    :nth-child(2) {
      top: 100px;
      z-index: 1;
    }
  }
}
.container__input_group__select_group__input {
  font-family: inherit;
}
