@import 'src/theme/dimensions';
@import 'src/theme/colors.module.scss';

.outerContainer {
    position: relative;
}
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    padding-inline: 5%;
    z-index: 1;

    .subtitle {
        line-height: 28px;
        color: $secondary-500;
        align-self: flex-start;
    }

    @media screen and #{$mobileView} {
        padding-inline: 16px;
        align-items: center;
    }
}

.header {
    // width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    .congratulations {
        line-height: 32px;
        color: $secondary-500;
        text-align: center;
    }

    .booking {
        color: $gray-500;

        .bookingId {
            color: $primary-500;
        }
    }
}

.mobileHeader {
    display: none;
    @media screen and #{$mobileView} {
        display: flex;
        margin-bottom: 16px;
        width: 100%;
        justify-content: flex-start;

        .mobileTitle {
            flex: 1;
            text-align: center;
            line-height: 24px;
            color: $secondary-500;
        }
    }
}
.row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex: 1;

    .myBooingsBtn {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        cursor: pointer;
        @media screen and #{$mobileView} {
            display: none;
        }

        h6 {
            font-size: 14px;
            line-height: 20px;
            margin-inline-end: 15.5px;
            color: $gray-500;
        }

        .icon {
            width: 5px;
            height: 10px;
            margin-top: 6px;

            &.iconReversed {
                transform: rotateY(180deg);
            }
        }
    }
}
.detailsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    width: 100%;
    // min-height: fit-content;
    background-color: $primary-25;
    border: 1px solid $primary-100;
    border-radius: 16px;
    margin-bottom: 16px;
    justify-content: space-between;

    .ditailsRow {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .middleSection {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-inline-start: 10.5px;
            align-items: flex-start;

            .title {
                line-height: 24px;
                color: $primary-600;
                margin-bottom: 4px;
            }
        }

        .lastSection {
            display: flex;
            align-items: center;
            align-self: flex-end;
            cursor: pointer;
            // background-color: bisque;
            h6 {
                font-size: 14px;
                line-height: 20px;
                margin-inline-end: 13px;
                color: $gray-500;
            }

            .icon {
                margin-bottom: 6px;
            }
        }
    }

    .info {
        line-height: 24px;
        color: $gray-500;
        margin-inline-start: 2%;
    }
}

.carSpecContainer {
    display: flex;
    width: 100%;
    border: 1px solid $gray-200;
    padding: 12px 24px;
    border-radius: 16px;
    margin-bottom: 16px;

    @media screen and #{$mobileView} {
        padding-bottom: 10px;
    }

    .cardDetailsFirstRow {
        display: flex;
        width: 100%;
        padding-bottom: 16px;
        margin-top: 16px;
        justify-content: space-between;
        background-color: $white;

        img {
            width: 200px;
            height: 112.5px;
            margin-inline-end: 16px;
            border-radius: 8px;
            aspect-ratio: 16/9;
            object-fit: cover;
            @media screen and #{$mobileView} {
                display: none;
            }
            border-radius: 12px;
        }

        .carNameContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 12px;

            .detailsRow {
                display: flex;
                justify-content: space-between;
                align-content: center;
                align-items: center;

                .name {
                    color: $secondary-500;
                    margin-bottom: 0;
                }

                img {
                    width: 10px;
                    height: 10px;
                    margin-inline-end: 0px;
                }

                &.marginTop8 {
                    // margin-top: 8px;
                    @media screen and (max-width: 850px) {
                        margin-top: 0px;
                    }

                    .currency {
                        color: $gray-500;

                        .price {
                            color: $primary-600;
                        }
                    }

                    .startingPrice {
                        color: $secondary-500;
                    }
                }
            }
        }

        .specsRows {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: baseline;
            gap: 24px;
            @media screen and #{$mobileView} {
                // justify-content: space-evenly;
            }

            h5 {
                @media screen and #{$mobileView} {
                    // width: 40px;
                }
            }

            div {
                display: flex;
                align-content: center;
                gap: 8px;
            }

            .specsText {
                white-space: pre;
                color: $gray-500;
            }

            .specIcon {
                width: 16.67px;
                height: 16.67px;

                &.calendarIcon {
                    width: 15px;
                }
            }

            .kmContainer {
                @media screen and (max-width: 400) {
                    flex: 1;
                }
            }

            .gearboxContainer {
                @media screen and (max-width: 400) {
                    margin-inline-end: 12px;
                }
            }

            @media screen and #{$mobileView} {
                align-items: flex-start;
                margin-inline-end: 35px;
                margin-top: 5px;
            }
        }

        @media screen and (max-width: 850px) {
            width: 100%;
            border: none;
            padding-bottom: 0px;

            .icon {
                width: 34px;
                height: 34 p;
                margin-inline-end: 16px;

                &.iconReversed {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

.financingTitle {
    color: $secondary;
}
.financingSubtitle {
    color: $secondary-400;
}
.partnersTitle {
    color: $blue-light-700;
}

.partners {
    display: flex;
    justify-content: center;
    padding-block: 12px;
    margin-bottom: 8px;

    .valu {
        width: 54px;
        height: 21px;
    }

    .aiBank {
        width: 91px;
        height: 21px;
    }

    .globalCorp {
        width: 67px;
        height: 29px;
    }
}

.applyForFinanceBtn {
    width: 100%;
    height: 48px;
    background-color: $blue-light-500;
    box-shadow: 0px 1px 2px $gray-900-opac;
    border-radius: 8px;
    border-width: 0px;
    color: $blue-light-50;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconReversed {
    transform: rotateY(180deg);
}

.carouselContainer {
    width: 100%;
    min-width: 350px;
    max-width: 498;
    margin-top: 16px;
    margin-bottom: 72px;
    height: 110px;
    @media screen and #{$mobileView} {
        display: none;
    }
}

.mobileCheckoutBtn {
    display: none;
    z-index: 10;
    background-color: $white;
    @media screen and #{$mobileView} {
        display: flex;
        position: absolute;
        min-height: 106px;
        width: 100%;
        padding-inline: 16px;
        bottom: 0;
        border-top: 1px solid $gray-200;
        margin-bottom: 0;
        padding-block: 18px;
        gap: 8px;

        .text {
            line-height: 24px;
            margin-bottom: 0px;
        }
        .homeBtn {
            border: 1px solid $gray-300;
            height: 48px;
            box-shadow: 0px 1px 2px $gray-900-opac;
            border-radius: 8px;
            padding: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.mobileCongratulations {
    width: 100%;
    display: flex;
    gap: 12px;
    margin-bottom: 24px;

    .title {
        color: $black;
    }

    .subtitle {
        color: $gray-500;
        display: flex;

        .span {
            color: $primary-500;
            margin-inline-start: 4px;
            display: inline;
        }
    }
}
.img {
    object-fit: contain;
}
