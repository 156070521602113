// .carousel_container {
//   overflow: hidden;
// }

// .carousel {
//   display: flex;
//   transition: transform 0.3s ease;
// }

// .carousel_track {
//   display: flex;
// }

// .carousel_item {
//   flex: 0 0 100%; /* Each item takes the full width of the carousel */
//   padding: 10px;
//   border: 1px solid #ccc;
// }

// .circular_carousel_container {
//   overflow: hidden;
//   position: relative;
// }

// .circular_carousel {
//   display: flex;
// }

// .circular_carousel_item {
//   flex: 0 0 100%; /* Each item takes the full width of the carousel */
//   padding: 10px;
//   border: 1px solid #ccc;
// }

// @keyframes slideInFromRight {
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }

// @keyframes slideOutToLeft {
//   0% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// }

.slider_container {
  position: relative;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.3s ease;
}

.slide {
  flex: 0 0 100%;
}

.slider_controls {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
