@import "src/theme/colors.module.scss";
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 850px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    height: 94px;
    padding: 12px 16px;
  }
}

.btn {
  height: 48px;
  border-radius: 8px;
  padding: 12px 20px;

  &.back {
    background-color: $white;
    color: #000000;
    border: 1px solid $gray-300;

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  &.submit {
    background-color: $primary;
    color: $white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    @media screen and (max-width: 850px) {
      width: 100%;
    }

    &:disabled {
      background-color: $gray-300;
    }
  }
}
.iconReversed {
  transform: rotateY(180deg);
}
