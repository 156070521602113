.blurOverlay {
  backdrop-filter: blur(23px);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 30;
  border-radius: 12px;
  z-index: 1;
}
.blurOverlay2 {
  filter: blur(40px);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.7;
  z-index: 1;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  height: 100%;
}
.container {
  position: absolute;
  left: 0;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  border-radius: 8px;
}
