@import 'src/theme/colors.module.scss';
.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    user-select: none;
    cursor: pointer;
}
.buttonActive {
    background: $primary-50;
    border-radius: 6px;
}
