@import '/src/theme/colors.module.scss';

.disclaimer_container {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    border-radius: 8px;
    background-color: $error-25;
    border: solid 1px $error-300;
}
.warning {
    background-color: $warning-25;
    border: solid 1px $warning-500;
}
