@import "src/theme/colors.module.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  @media screen and (max-width: 850px) {
    padding: 0px 16px 0px;
    margin-top: -16px;
    background-color: $gray-50;
  }
}

.textContainer {
  white-space: pre-wrap;

  a {
    text-decoration: underline;
  }
}

.text {
  // text-decoration: none;
  color: $primary;
}

.text:hover {
  color: $primary;
}

.checkbox {
  min-width: min-content;
  padding: 0px 0px 0px;
}
