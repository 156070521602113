@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.notFoundPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding: 80px 24px 120px;

  @media screen and #{$desktopView} {
    flex-direction: row-reverse;
    padding: 80px 48px 120px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  @media screen and #{$desktopView} {
    align-items: flex-start;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 12px;

  @media screen and #{$desktopView} {
    flex-direction: row-reverse;
  }
}

.browseAllCarsButton {
  width: 100%;
  color: $white;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  padding: 6px 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: #005b34;
  }

  @media screen and #{$desktopView} {
    width: auto;
  }
}

.homeButton {
  width: 100%;
  color: $gray-700;
  border: 1px solid $gray-300;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s ease;
  transition: border 0.3s ease;
  gap: 12px;

  &:hover {
    color: $black;
    border: 1px solid $primary;
    background-color: $gray-25;
  }

  @media screen and #{$desktopView} {
    width: auto;
  }
}

.image {
  width: 602px;
  height: 251px;
}

.noPageFoundLabel {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;

  @media screen and #{$desktopView} {
    font-size: 3.75rem;
    line-height: 4.5rem;
    letter-spacing: -2%;
    font-weight: 500;
  }
}
