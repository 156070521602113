@import "src/theme/dimensions.scss";
@import "src/theme/colors.module.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px 20px;
  gap: 24px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

.title {
  text-align: center;
  color: $primary;
}

.subTitle {
  line-height: 20px;
  text-align: center;
  color: $secondary;
}

.financiers {
  display: grid;
  grid-template-areas: "ol qnb val ai";
  gap: 0px 16px;
  padding: 0px;
  align-items: center;
  gap: 16px;

  @media #{$mobileView} {
    grid-template-areas:
            "ol ai"
            "qnb val";
  }
}
.image {
  width: 128px;
  object-fit: contain;
  height: 100%;
  @media #{$mobileView} {
    width: 100px;
  }
}

.pageContainer {
  padding-inline: 7%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #f8fafb;
  padding-top: 44px;

  @media screen and (max-width: 850px) {
    flex-direction: column;
    background-color: white;
    justify-content: flex-start;
    padding-top: 0px;
    padding-inline: 0px;
  }
}

.mobileHeader {
  display: none;
  width: 100%;
  display: flex;
  align-items: center;

  &__backBtnContainer {
    height: 55px;
    width: 26%;
    max-width: 110px;
    background-color: $gray-50;
    border-radius: 65px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: none;

    h5 {
      margin-bottom: 0%;
      font-family: "CeraPro-Regular", "Vazirmatn", sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.01em;
    }

    .reversed {
      transform: rotateY(180deg);
    }
  }

  h4 {
    font-style: normal;
    font-size: 24px;
    line-height: 100%;
    margin-inline-start: 29px;
    color: $dark-gray;
  }

  @media screen and (min-width: 850px) {
    display: none;
  }
}
.topics {
  background: $white;
  width: 35%;
  border-radius: 12px;
  height: fit-content;
  padding-inline: 2%;
  padding-block: 32px;
  position: sticky;
  top: 40px;
  margin-bottom: 40px;
  left: 0;
  right: 0;
  z-index: 10;

  &__title {
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: $dark-gray;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 850px) {
    &__title {
      display: none;
    }
    width: 100%;
    display: flex;
    background-color: transparent;
    flex-flow: row;
    flex-wrap: nowrap;
    overflow: scroll;
    padding-block-end: 2px;
    padding-block-start: 16px;
    padding-inline-start: 3%;
    padding-inline-end: 0px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: white;
    margin-bottom: 0px;
  }
}
.browseButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  background: $white;
  width: 100%;
  border: 1px solid $gray-300;
  text-decoration: none;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 24px;
  color: $gray-700;

  :focus {
    color: $secondary;
  }
}
.questions {
  background: $white;
  width: 50%;
  border-radius: 12px;
  padding-inline: 2%;
  margin-bottom: 40px;
  // padding-block: 32px;

  h5 {
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: $dark-gray;
    margin-bottom: 24px;
    @media screen and (max-width: 850px) {
      font-size: 16px;
      line-height: 24px;
      color: $secondary;
      font-family: "CeraPro-Medium";
      margin-bottom: 12px;

      :not(:first-child) {
        margin-top: 12px;
      }
    }
  }

  @media screen and (max-width: 850px) {
    width: 100%;
    padding-block: 0px;
    padding-inline: 16px;
  }
}

.topic {
  width: 100%;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  border-radius: 13px;
  padding-block: 24px;
  cursor: pointer;

  h6 {
    font-family: "CeraPro-Regular", "Vazirmatn", sans-serif;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: $dark-gray;
  }

  &.selected {
    background: #f8f8f7;
    height: 63px;

    h6 {
      color: $dark-gray;
    }
  }

  &.active-scroll-spy {
    background-color: yellowgreen;
    // border-radius: 15px;
  }

  @media screen and (max-width: 850px) {
    display: flex;
    background-color: transparent;
    padding: 1px 14px;
    flex: 1;
    align-items: flex-end;
    &.selected {
      background: $primary-50;
      border-radius: 6px;
      display: flex;
      height: 33px;

      h6 {
        font-family: "CeraPro-Medium";

        width: max-content;
        font-size: 16px;
        color: $primary-700;
      }
    }
    h6 {
      font-family: "CeraPro-Medium";

      width: max-content;
      font-size: 16px;
      color: $gray;
    }
  }
}

.accordion {
  width: 100%;
  padding: 24px 16px;
  background-color: $gray-50;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  height: 74px;
  color: $dark-gray;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &__arrow {
    border: solid 1px $gray-25;
    border-radius: 39px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    background-color: $gray-50;
    width: 100%;
    height: 0px;
    padding: 0;
    overflow: hidden;
    background-color: white;
    word-wrap: break-word;
    border-radius: 13px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: ease-in-out 0.2s;
  }
}
.accordion_active {
  background-color: $gray-50;
  width: 100%;
  padding: 24px 16px;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  min-height: 74px;
  color: $dark-gray;
  align-items: center;
  height: 74px;

  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all ease-in-out;

  &__arrow {
    border-radius: 39px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    background-color: $gray-100;
    width: 100%;
    padding: 24px 16px;
    word-wrap: break-word;
    border-radius: 13px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: ease-in-out 0.2s;
    white-space: pre-line;
    color: $dark-gray;
  }
}
