.header_container {
  position: relative;
}
.header_container_img {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.header_text_container {
  position: absolute;
  top: 21%;
  transform: translate(-50%, -21%);
  left: 50%;
  text-align: center;
}
.header_text {
  font-weight: 600;
  font-size: 5vw;
  opacity: 1;
  color: white;
  line-height: 1;
  letter-spacing: -3;

  &[letter-lang="ar"] {
    line-height: 1.3;
    letter-spacing: 0;
  }
}

.green_checkmark {
  margin-top: 0.2vw;
  margin-right: 0.6vw;
  width: 1.7vw;
}
.points_text {
  font-size: 1.3vw;
  color: white;
  opacity: 1;
  letter-spacing: -0.03;
}
