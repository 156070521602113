@import 'src/theme/colors.module.scss';

.signInBtn {
  border-radius: 24px;
  background-color: $primary-50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  // border: 1px solid red;
  gap: 8px;
  padding: 8px 16px;
  // white-space: nowrap;
}

.avatarContainer {
  background-color: $white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loggedBtn {
    border: 1px solid $primary-700;
    border-radius: 24px;
    width: 75px;
    height: 36px;
    padding: 2.5px;
    padding-inline-end: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__avatar {
        background-color: $primary-700;
        border-radius: 18px;
        width: 29px !important;
        height: 29px !important;
        color: white;
        text-align: center;
        display: flex;
        margin-inline-end: 8px;
        align-items: center;
        justify-content: center;
    }
}

.sectionContainer {
  border: 1px solid $gray-100;
  position: absolute;
  width: 276px;
  top: 52px;
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px $gray-900-14;
}

.dataContainer {
  position: relative;
  background-color: white;
  border-radius: 8px;
  height: 100%;
  padding: 24px 16px;
  z-index: 10;
}

.dataContainerBefore {
  content: '';
  position: absolute;
  top: -7px;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border: 1px solid;
  border-color: $gray-100 transparent transparent $gray-100;
}

.dataContainerLTR {
  @extend .dataContainer;

  &::before {
    @extend .dataContainerBefore;
    right: 12%;
  }
}

.dataContainerRTL {
  @extend .dataContainer;

  &::before {
    @extend .dataContainerBefore;
    left: 12%;
  }
}

.nameContainer {
  border-bottom: 1px solid $gray-200;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.initialContainer {
  background-color: $primary-700;
  border-radius: 33.25px;
  width: 56px;
  height: 56px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  font-size: 30px;
  line-height: 38px;
}

.nameData {
  margin-bottom: 8px;
}

.emailContainer {
  border-bottom: 1px solid $gray-200;
  padding: 16px 0px;
  display: flex;
  align-items: center;
}

.logoutContainer {
  padding-top: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin-inline-end: 6px;
}

.initialContent {
  text-align: center;
  line-height: 1.5;
  display: inline-block;
}

.tagContainer {
  background-color: $primary-50;
  border-radius: 8px;
  display: flex;
  padding: 16px;
  gap: 12px;
  align-items: center;
  margin-bottom: 8px;
}

.iconContainer {
  background-color: $white;
  width: 64px;
  height: 64px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  height: 1px;
  background-color: $gray-200;
}

.personalInfoContainer {
  padding: 16px 0;
  display: flex;
  gap: 6px;
  align-items: center;
}

.optionsWrapper {
  padding: 8px 0;
}

.optionContainer {
  padding: 16px 0;
  display: flex;
  gap: 6px;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.nonClickable {
  cursor: no-drop;
}

.clickable {
  cursor: pointer;
}

.sectionContainerWrapperRTL {
  right: auto;
  left: 0;
}

.sectionContainerWrapperLTR {
  left: auto;
  right: 0;
}

.temporarlyDisabled {
  opacity: 0.5;
  cursor: no-drop;
}
