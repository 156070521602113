@import "src/theme/colors.module.scss";
.container {
  width: 100%;
  padding: 14px;
  border: 1px solid $gray-200;
  border-radius: 8px;


  &.active {
    border: 1px solid $green-600;
    background-color: $green-50;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // cursor: pointer;


    &__content {
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;

      &__text {
        color: $gray-700;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 44px;
    }
  }

  &__models {
    height: 0;
    transition: all 0.1s;
    overflow: auto;

    &.active {
      height: fit-content;
    }

    &__text {
      color: $gray-500;
      font-size: 0.9rem;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &__checkbox {
      margin-bottom: 8px;
    }
  }
}
