@import "src/theme/colors.module.scss";

.container {
  background-color: transparent;
  width: 100%;
  padding: 14px;
  border: 1px solid $gray-200;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.active {
    border: 1px solid $green-700;
    background-color: $green-50;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      color: $gray-700;
    }
  }
}
