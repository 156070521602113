@import 'src/theme/colors.module.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    &.active {
        border: 1px solid $green-600;
        background-color: $green-50;
    }
}

.default {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid $gray-300;
    &__check {
        width: 11px;
        height: 12px;
    }
}

.contactInfo {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid $gray-300;
    &__check {
        width: 12px;
        height: 12px;
    }
}
