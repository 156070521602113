.faqAnsweredQuestions {
  li {
    list-style-type: circle;
  }
}
.newsList {
  padding: 0;

  li {
    margin-bottom: 100px;

    .details {
      margin-inline-end: 100px;

      h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 100%;
        letter-spacing: -0.04em;
        color: #1a273b;

        span {
          color: #3b9c53;
        }
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #1a273b;
      }

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.03em;
        color: #1a273b;
        opacity: 0.6;
        margin-bottom: 20px;

        &.lineHeight {
          line-height: 220%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:first-child {
      .details {
        margin-inline-start: 100px;
        margin-inline-end: 0;
      }
    }
  }
}

.faq {
  background: #f8fafb;
  border-radius: 12px;
  padding: 60px 0 45px;
  padding-bottom: 0px;

  .detailsTitle {
    width: 100%;

    p {
      margin-bottom: 35px;
      width: 100%;
    }
  }

  .accordion {
    width: 60%;
    margin-bottom: 20px;
  }
}
.whiteButton {
  background: #fff;
  border-radius: 65px;
  font-size: 16px;
  padding: 10px 40px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  line-height: 30px;
  display: inline-block;
  color: #1a273b;
  margin-bottom: 130px;

  &:hover {
    color: #1a273b;
    background: #fff;
  }
}

.faqs_container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
}
.accordion_container {
  width: 40%;
  background-color: white;
  padding: 32px;
  border-radius: 12px;
  @media screen and (max-width: 900px) {
    width: 90%;
  }
}
.accordion_header_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  color: #212529;
  border-bottom: 1px solid rgb(157 157 157 / 13%);
  width: 100%;
  flex-wrap: wrap;
  text-align: start;
}
.accordion_body {
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: height 1s ease-in-out;

  &.open {
    height: fit-content;
    padding: 16px 20px;
    border-bottom: 1px solid rgb(157 157 157 / 13%);
  }
}
.all_faqs_container {
  border: 1px solid rgb(157 157 157 / 13%);
  border-radius: 8px;
}
.arrow {
  transition: all ease-in-out 0.3s;

  &.open {
    transform: rotate(180deg);
  }
}
