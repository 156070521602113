.stepper_header_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    box-shadow: none;
    position: relative;
}

.stepper_navigator_container {
    display: flex;
    gap: 8px;
    cursor: pointer;
}
.stepper_navigator_icon {
    width: 44px;
    height: 44px;
    background-color: #e6fbf2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepper_progress_container {
    width: 100%;
    height: 3px;
    border-radius: 8px;
    background-color: #d0d5dd;
}
.stepper_progress_indicator {
    height: 100%;
    border-radius: 8px;
    background-color: #009b5d;
}

.step_navigation_button {
    background-color: white;
    border: none;
    padding: 0;
}
