@import "src/theme/colors.module.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 8px 7px 8px;
  width: 138px;
  border-radius: 6px;
  margin: 0;
  background: none;
  border: none;

  &.active {
    background-color: $green-50;
    border: 1px solid $green-50;
  }

  &__text {
    color: $gray-500;
  }

  &__count {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $gray-500;

    &.active {
      color: $green-700;
      padding: 2px 8px 2px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $green-100;
      border-radius: 24px;
      width: 23px;
    }
  }
}
