@import 'src/theme/dimensions';
@import 'src/theme/colors.module.scss';

.priceSectionContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.pricingContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    @media screen and #{$desktopView} {
        gap: 24px;
    }
}
.flexCenter {
    display: flex;
    align-items: center;
}

.displayFlex {
    display: flex;
}

.discountPrice {
    font-weight: 600;
    margin-inline: 2px;
    font-size: 1rem;
    color: $warning-700;
}

.discountCurrency {
    margin-inline: 2px;
    color: $warning-700;
}

.discountPercentageContainer {
    background-color: $warning-50;
    padding: 4px 10px 4px 10px;
    border-radius: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    margin: 4px;
    direction: ltr;
}
.discountPercentageValue {
    line-height: 1.25rem;
    color: $warning-700;
    font-size: 0.75rem;
}
.whatsIncluded {
    margin-top: 8px;
    @media screen and #{$mobileView} {
        margin-bottom: 13px;
    }
}
