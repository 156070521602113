@import "src/theme/colors.module.scss";
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  .title {
    color: $secondary-500;
  }

  @media screen and (max-width: 850px) {
    background-color: $gray-50;
    padding: 16px;
    flex: 1;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .label {
    color: $gray-700;

    .labelSpan {
      color: $gray-500;
    }
  }

  input {
    border: 1px solid $gray-300;
    border-radius: 8px;
    height: 40px;
    padding: 8px 12px;
    outline: none;
    touch-action: none;
    color: $gray-900;
    font-family: inherit;

    ::placeholder {
      color: $gray-500;
    }
  }
}

@import "src/theme/colors.module.scss";
.labelAndIcon {
  display: flex;
  align-items: center;

  .labelIcon {
    margin-inline-end: 6.5px;
  }
}
.fileInputContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-300;
  border-radius: 8px;
  padding: 16px;

  .title {
    color: $secondary;
  }

  .subtitle {
    color: $gray-500;
    margin-top: 8px;
    margin-bottom: 4px;
  }
}
