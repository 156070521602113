.nextArrow {
  width: 56px;
  height: 56px;
  background-color: white;
  border: 1px solid #eaecf0;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -64px;
  left: 88px;
}
.prevArrow {
  width: 56px;
  height: 56px;
  background-color: white;
  border: 1px solid #eaecf0;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scaleX(-1);
  position: absolute;
  bottom: -64px;
  left: 0px;
}
.mediaCoverage {
  padding-left: 7.2vw;
  padding-right: 7.2vw;
  position: relative;
  padding-bottom: 64px;
  margin-top: 48px;

  &__title {
    color: rgba(49, 138, 72, 1);
    opacity: 1;
    text-align: start;
    margin-bottom: 12px;
    display: block;
    margin-bottom: 24px;
  }
}
