.stepperStepContainer {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  position: relative;
}

.stepperStepContainerActive {
  background-color: #f3fffa;
  border-color: #99ebcb;
}

.stepperStepItemContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 0px;
  transition: all ease-in-out 0.1s;
  overflow: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 0;
  left: 0;
  background-color: white;
  z-index: 2;
  border-radius: 8px;
}

.stepsContainerOpen {
  height: 180px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
  0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  padding: 16px;
}

.stepperConnector {
  position: absolute;
  top: 103%;
  height: 9px;
  width: 1px;
  background-color: #d0d5dd;
}

.stepperConnectorActive {
  background-color: #66e2b0;
}
