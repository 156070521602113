@import 'src/theme/colors.module.scss';

.pageWrapper {
    min-height: calc(100vh - 80px);
}

.card {
    padding: 16px;
}
.topBar {
    height: 4px;
    width: 100%;
    background-color: $gray-100;
    margin-bottom: 24px;
    border-radius: 20px;
}
.progressBar {
    height: 100%;
    background-color: $primary-600;
    border-radius: 20px;
}
