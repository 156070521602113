@import 'src/theme/colors.module.scss';
@import 'src/theme/dimensions.scss';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    // gap: 16px;

    // @media screen and #{$mobileView} {
    //     gap: 8px;
    //     padding-bottom: 100px;
    //     padding-top: 10px;
    //     overflow: auto;
    // }
}
.options_container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    // @media screen and #{$desktopView} {
    //     display: none;
    //     height: 80%;
    //     overflow: auto;
    // }

    &.open {
        display: grid;
    }
}

.button_container {
    position: fixed;
    margin-inline-start: -16px;
    width: 100vw;
    bottom: 0px;
    background: white;
    padding: 16px 12px;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    border-top: 1px solid #eaecf0;
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 14px;

    flex-direction: column;

    @media screen and #{$desktopView} {
        position: inherit;
        width: 100%;
        box-shadow: none;
        margin: 0;
        padding: 0;
        border-top: 0;
    }
}

.address_container {
    display: flex;
    gap: 16px;
    padding: 12px 0px;
    border-bottom: 1px solid $gray-200;
    width: 100%;
    &_pin {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-50;
        border-radius: 8px;
        padding: 10px;
    }
    @media screen and #{$mobileView} {
        padding: 0px 0px 8px;
    }
}

.map_container {
    display: flex;
    gap: 12px;
    flex-direction: column;
    height: 100%;
    @media screen and #{$mobileView} {
        height: calc(100svh - 412px);
    }
}
