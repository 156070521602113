@import "src/theme/colors.module.scss";

.container {
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 1px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
}

.textWithGap{
  line-height: 20px;
  color: $gray-700;
  gap: 8px;
}

.textStyle{
  line-height: 20px;
  color: $gray-700;
}