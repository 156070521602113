.banner_contianer {
    display: none;
    @media screen and (max-width: 1100px) {
        display: block;
        position: relative;
    }
}
.desktop_header {
    display: block;
    @media screen and (max-width: 1100px) {
        display: none;
    }
}
.testimonialContainer {
    margin: 15px;
}
