@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.container {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 3;
  margin-bottom: 12px;
  @media #{$desktopView} {
    display: none;
  }
}
