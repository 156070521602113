.select_input_group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    position: relative;
}

.input {
    border: solid 1px #d0d5dd;
    text-indent: 12px;
    border-radius: 8px;
    outline: #66e2b0;
    height: 44px;
    color: black;
    position: relative;

    &:focus {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #e6fbf2;
        border-color: #66e2b0;
    }

    &[large-indent='large'] {
        padding-inline-end: 37px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &[small-text-area] {
        text-indent: 31% !important;
    }
}

.select_container {
    height: 0;
    padding: 0;
    background-color: white;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
        0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    flex-direction: column;
    gap: 16px;
    width: 770px;
    overflow: hidden;
    transition: all ease-in-out 0.2s;

    &.open {
        padding: 18px 8px;
        height: 306px;
        max-height: 306px;
        display: flex;

        &[extra-height] {
            padding: 18px 8px;
            height: 342px;
            display: flex;
        }
    }
}
.select_container_options {
    display: none;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    height: 80%;
    overflow: auto;

    &.open {
        display: grid;
    }
}

.search_input {
    outline: none;
    width: 100%;
    background-color: white;
    position: relative;
    border: none;
    border-bottom: solid 1px #eaecf0;
}

.content_icon {
    width: 23px;
    position: absolute;
    bottom: 15.9%;

    &[icon-variant='right'] {
        right: 10%;
    }

    &[icon-variant='left'] {
        left: 10%;
    }
}

.button_container {
    position: fixed;
    margin-inline-start: -16px;
    width: 100vw;
    bottom: 0px;
    background: white;
    padding: 16px 12px;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    border-top: 1px solid #eaecf0;
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 14px;
    height: 98px;
    @media screen and (min-width: 850px) {
        display: none;
    }
}

.location_dialog {
    width: 50vw;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 85vh;
}
