.header_layout {
  padding: 16px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #eaecf0;
}
.header_item_layout {
  padding: 0;
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
}
