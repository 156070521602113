.cardContainer {
  margin-right: 8px;
  height: 475px;
  padding-bottom: 32px;
  border-radius: 16px;
  border: solid 1px rgba(234, 236, 240, 1);
  text-align: center;
  position: relative;
}
.contentSection {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    // width: 100%;
    &__text {
      color: rgba(16, 24, 40, 1);
      float: left;
    }
  }

  &__paragraph {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    opacity: 1;
    margin-top: 12px;
    text-align: left;
  }
}

.newsMediaLogo {
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  bottom: 32px;
}
