@import 'src/theme/colors.module.scss';
.container {
    overflow: hidden;
    position: relative;
    height: 100%;
}
.content_container {
    height: 74%;
    overflow: scroll;
}

.header_container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    background-color: white;
    z-index: 1000;
    width: 100%;
    border-bottom: 1px solid #eaecf0;
}

.hub_images {
    padding: 15px 0px 15px 0px;

    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        width: 252px;
        height: 252px;
        object-fit: cover;
        border-radius: 8px;
    }
}
.hub_benefits {
    padding: 15px;
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eaecf0;
    .benefit {
        min-width: 33%;
        margin-right: 6px;
        display: flex;
        height: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        &:first-of-type {
            margin-right: 0px;
        }

        .benefit_img {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            background-color: #ccf5e5;
            margin-bottom: 10px;
        }
    }
}

.hub_details {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 16px;
    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:first-of-type {
            color: $primary;
        }
        img {
            margin: 0px 5px;
        }
    }
}
.hub_journey_cont {
    .hub_journey {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 24px;
        width: 100%;
        div {
            margin-top: 16px;
        }
        .hub_step {
            width: 65%;
            text-align: start;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            p {
                &:first-of-type {
                    color: $gray-800;
                }
                &:nth-of-type(2) {
                    color: $gray-600;
                }
            }
        }
    }
    margin-bottom: 8px;
}

.map_container {
    height: 180px;
    padding-bottom: 24px;
    border-bottom: 1px solid #eaecf0;
    margin-top: 16px;
    position: relative;
    @media screen and (max-width: 1100px) {
        height: 220px;
    }
}
.location_icon {
    position: absolute;
    top: 16px;
    left: 16px;
}
.direction_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.button_hint_cont {
    border-top: 1px solid #eaecf0;
    padding-top: 12px;
    padding-bottom: 21px;
}

.button {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
}

.next_step {
    font-size: 12px;
    margin-bottom: 8px;
}
.scrollable::-webkit-scrollbar {
    display: none;
}
