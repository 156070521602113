@import 'src/theme/dimensions.scss';
@import 'src/theme/colors.module.scss';
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px 20px;
    gap: 24px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.title {
    text-align: center;
    color: #009b5d;
}

.subTitle {
    line-height: 20px;
    text-align: center;
    color: #002d5d;
}

.financiers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px 16px;
    padding: 0px 32px;
    align-items: center;
}
.image {
    width: 128px;
    object-fit: contain;
    height: 70px;
    @media #{$mobileView} {
        width: 100px;
        height: 50px;
    }
}
