.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7.5%;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 33%;
}

.header {
  color: #002d5d;
  margin-bottom: 0;
  width: 100%;
}

.subHeader {
  color: #00673e;
}

.image {
  width: 60%;
  margin-top: -10px;
  object-fit: contain;
  height: fit-content;
}

.buttonsContainer {
  display: flex;
  gap: 13px;
}

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: 13.75rem;
  height: 48px;
  background: #009b5d;
  border: 1px solid #009b5d;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
  text-decoration: none;
}

.inverted {
  background-color: #002d5d;
  border: 1px solid #002d5d;
}

@media (max-width: 1024px) {
  .container {
    overflow: hidden;
    align-items: center;
    padding: 37px 10px 20px 20px;
    margin: 40px 7.5% 72px;
  }

  .infoContainer {
    width: 40%;
  }

  .image {
    width: 50%;
    object-fit: contain;
  }

  .actionButton {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    background-color: transparent;
    padding: 0;
    margin: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eaecf0;
    border-radius: 0;
    overflow: hidden;
  }

  .infoContainer {
    width: 100%;
    padding-inline: 16px;
    margin: 0 auto;
    gap: 8px;
    margin-bottom: 32px;
  }

  .header {
    margin: 0 auto;
  }

  .subHeader {
    margin-bottom: 14px;
  }

  .image {
    width: 100%;
    margin-top: 0;
    object-fit: contain;
  }

  .buttonsContainer {
    gap: 16px;
    margin: 0 auto;
    width: 95%;
  }

  .actionButton {
    width: 50%;
  }
}
