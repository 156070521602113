@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.container {
  background-color: $gray-50;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 90%;
  margin: auto;
  height: 119px;
  position: relative;
  flex-direction: column;
  @media #{$desktopView} {
    display: none;
  }
}
.bannerDecoration {
  position: absolute;
  background-color: $primary-100;
  transform: skew(16deg);
  height: 20px;
}

.bannerDecorationPosition1 {
  width: 58.51px;
  right: 66.63%;
  top: 9.48%;
  bottom: 73.17%;
}

.bannerDecorationPosition2 {
  width: 58.51px;
  left: 53.63%;
  right: 29.31%;
  top: 76.71%;
  bottom: 5.95%;
}

.bannerDecorationPosition3 {
  width: 44.21px;
  left: 79.53%;
  right: 7.58%;
  top: 36.81%;
  bottom: 45.85%;
}
