@import "src/theme/colors.module.scss";

.card_container {
  width: 100%;
  height: 100%;
  border: 1px solid $gray-300;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  background-color: white;
}
