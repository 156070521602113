@import 'src/theme/dimensions';
@import 'src/theme/colors.module.scss';

.container {
    border: 1px solid $error-300;
    border-radius: 8px;
    height: 3vh;
    min-height: 52px;

    @media screen and #{$desktopView} {
        min-width: 25%;
    }

    @media screen and #{$mobileView} {
        width: 88%;
    }

    &.success {
        background-color: $success-25;
        border-color: $success-300;
    }
    &.info {
        background-color: $gray-25;
        border-color: $gray-300;
    }
    &.error {
        background-color: $error-25;
        border-color: $error-300;
    }

    &__innerContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 16px;

        .text {
            flex: 1;
            margin-inline-start: 12px;
            &.success {
                color: $success-700;
            }
            &.info {
                color: $gray-700;
            }
            &.error {
                color: $error-700;
            }
        }

        &__closeBtn {
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }
}

.toasterLTR {
    left: 50% !important;
    transform: translateX(-50%) !important;
    right: auto !important;
    bottom: 85px !important;
}
.toasterRTL {
    right: 50% !important;
    transform: translateX(50%) !important;
    left: auto !important;
    bottom: 85px !important;
}
