@import "src/theme/colors.module.scss";

.flex_column_container {
  display: flex;
}

.card_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  width: 100%;
  background-color: $primary-25;
  border: 1px solid $primary-100;
  border-radius: 16px;
  justify-content: space-between;
  gap: 20px;
}
