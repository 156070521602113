@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.container {
  width: 88%;
  background-color: $error-25;
  border: 1px solid $error-300;
  border-radius: 8px;
  // height: 3vh;
  min-height: 52px;
  margin-top: 20px;

  &.success {
    background-color: $success-25;
    border-color: $success-300;
  }

  &__innerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    align-items: center;

    h6 {
      flex: 1;
      margin-inline-start: 14px;
      // font-family: 'CeraPro-Medium';
      font-size: 14px;
      line-height: 20px;
      color: $error-700;

      &.success {
        color: $success-700;
      }
    }

    &__closeBtn {
      background-color: transparent;
      border: none;
    }
  }
}
